<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>

    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--500" no-body>
          <div
              v-if="this.$store.state.noBankID && this.$store.state.updateAML"
              class="alert alert-warning bg-red text-white"
              role="alert"
          >
            <strong>Advarsel!</strong> Din brukerkonto må autentiseres med BankID, og kundedata må oppdateres i samsvar med kravene i hvitvaskingsloven. Vi ber om at dette gjøres så snart som mulig
            <a
                href="#"
                class="font-weight-bold text- text-white"
                @click="startBankIDauth"
            ><u>her.</u></a
            >
          </div>
          <div
            v-else-if="this.$store.state.noBankID"
            class="alert alert-warning bg-red text-white"
            role="alert"
          >
            <strong>Advarsel!</strong> Din brukerkonto må autentiseres med
            BankID. Dette må gjøres snarest. Du kan gjøre det nå ved å klikke
            <a
              href="#"
              class="font-weight-bold text- text-white"
              @click="startBankIDauth"
              ><u>her</u></a
            >
          </div>
          <div
              v-else-if="this.$store.state.updateAML"
              class="alert alert-warning bg-red text-white"
              role="alert"
          >
            <strong>Advarsel!</strong> Dine kundedata må oppdateres i samsvar med kravene i hvitvaskingsloven. Du kan gjøre det nå ved å klikke
            <router-link
                class="font-weight-bold text- text-white"
                to="/user/details"
            ><u>her</u></router-link>
          </div>

          <div v-bind:key="message.messageText" v-for="message in messages">
            <div v-if="message.messageHallID == 0">
              <div
                v-if="message.messageType == 'error'"
                class="alert alert-danger text-white mb-1"
                role="alert"
              >
                <strong>{{ message.messageHeader }}</strong>
                {{ message.messageText }}
              </div>
              <div
                v-else-if="message.messageType == 'warning'"
                class="alert alert-warning text-white mb-1"
                role="alert"
              >
                <strong>{{ message.messageHeader }}</strong>
                {{ message.messageText }}
              </div>
              <div
                v-else-if="message.messageType == 'info'"
                class="alert alert-info text-white mb-1"
                role="alert"
              >
                <strong>{{ message.messageHeader }}</strong>
                {{ message.messageText }}
              </div>
              <div
                v-else
                class="alert alert-default text-white mb-1"
                role="alert"
              >
                <strong>{{ message.messageHeader }}</strong>
                {{ message.messageText }}
              </div>
            </div>
          </div>
          <div v-if="$store.state.forceAML">
            <div class="col-12 mb-4">
              <h5>Oppdatering av din informasjon er nødvendig</h5>
              <p>For å kunne spille videre på nett må vi be deg oppdatere din informasjon. Dette er et krav i henhold til hvitvaskingsloven (AML) for å sikre at vi oppfyller gjeldende regelverk og bidrar til trygg og ansvarlig bruk av våre tjenester.</p>
              <p>Vennligst fullfør de nødvendige oppdateringene. Når dette er gjort, vil du igjen få tilgang til våre spilltjenester.</p>
              <p>Vi takker for din forståelse og for at du hjelper oss med å etterleve lovpålagte krav.</p>
              <base-button
                  class="center-button"
                type="success"
                @click="$store.state.noBankID ? startBankIDauth() : $router.push('/user/details')"
                >Oppdater informasjon</base-button>
            </div>
          </div>
          <div
            class="px-2 px-sm-2 px-md-3 px-lg-4 px-xl-4"
            v-else-if="!$route.params.id"
          >
            <div class="row justify-content-center"></div>
            <h4 class="mt-4 mb-2">Mine bingohaller</h4>
            <div v-if="haller.length == 0">
              <p>Finner ingen haller med ditt mobilnummer registrert</p>
              <p>
                Hvis dette er feil, bør du kontakte bingolokalet for å
                kontrollere at de har registrert korrekt mobilnummer på ditt
                spillekort.
              </p>
              <div v-if="true == false">
                <p>
                  Hvis du ikke har noen kundeforhold, kan vi opprette et
                  kundekort på din lokale bingosalong ved at du oppgir adresse
                  og postnummer.
                </p>
                <div
                  v-if="createsuccess"
                  class="alert alert-success"
                  role="alert"
                >
                  <strong>Utført!</strong> {{ createsuccess }}
                </div>
                <div v-if="createerror" class="alert alert-danger" role="alert">
                  <strong>Oida!</strong> {{ createerror }}
                </div>
                <div v-if="nearhaller.length == 0" class="row">
                  <div class="col-9 xs-col-10 col-md-10">
                    <label for="addr">Bostedsadresse</label>
                    <base-input
                      alternative
                      type="text"
                      name="addr"
                      id="addr"
                      placeholder="Gateadresse"
                      v-model="newAddr"
                    >
                    </base-input>
                  </div>
                  <div class="col-3 xs-col-2 col-md-2">
                    <label for="postnr">Postnummer</label>
                    <base-input
                      alternative
                      type="text"
                      name="postnr"
                      id="postnr"
                      placeholder="Postnummer"
                      v-model="newPostnr"
                    >
                    </base-input>
                  </div>

                  <div class="col-12">
                    <button
                      type="button"
                      class="btn btn-success float-right"
                      @click="createUser"
                    >
                      Finn lokale bingosalonger
                    </button>
                  </div>
                </div>
                <div v-if="nearhaller.length > 0 && !newCardCreationStarted">
                  <h5 class="mt-4 mb-2">Velg ønsket avdeling</h5>
                  <near-bingo-hall
                    v-for="hall in nearhaller"
                    @chooseBingo="chooseBingo"
                    @openCard="openCardBox"
                    :hall="hall"
                    :key="hall.BingoID"
                    :ref="hall.BingoID"
                    class="mb-2 px-1 px-sm-1 px-md-2 px-lg-3 px-xl-3"
                  ></near-bingo-hall>
                </div>
              </div>
            </div>
            <div v-if="!this.$store.state.forceAML">
            <my-bingo-hall
              v-for="hall in haller"
              @playMainGame="playMainGame"
              @playMainGame2="playMainGame2"
              @playMainGame3="playMainGame3"
              @playRemoteMainGame="playRemoteMainGame"
              @openTransactionsBox="openTransactionsBox"
              @openCard="openCardBox"
              @withdrawCard="withdrawCardBox"
              :messages="messages"
              :hall="hall"
              :key="hall.BingoID"
              :ref="hall.BingoID"
              class="mb-2 px-1 px-sm-1 px-md-2 px-lg-3 px-xl-3"
              >test</my-bingo-hall
            ></div>
            <div class="col-12 mb-3 mt-3 text-center">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                @click="playPause"
              >
                Spillepause
              </button>
            </div>
          </div>
          <div v-else>
            <!-- Get bingo with the BingoID match params.id -->
            <bingo-page
              v-for="hall in haller.filter(
                (hall) => hall.BingoID == $route.params.id
              )"
              @playMainGame="playMainGame"
              @playMainGame2="playMainGame2"
              @playMainGame3="playMainGame3"
              @playRemoteMainGame="playRemoteMainGame"
              @openTransactionsBox="openTransactionsBox"
              @openCard="openCardBox"
              @withdrawCard="withdrawCardBox"
              :messages="messages"
              :hall="hall"
              :key="hall.BingoID"
              :ref="hall.BingoID"
              class="mb-2 px-1 px-sm-1 px-md-2 px-lg-3 px-xl-3"
              >test</bingo-page
            >
          </div>
        </card>
      </div>
    </section>
    <!-- Modal bankkort -->
    <div v-if="showCardBox">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Kortinnskudd
                    {{ netsAmount > 0 ? "kr " + netsAmount + ",-" : "" }} på
                    {{ currentHall.BingoName }}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showCardBox = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body m-0 p-0">
                  <div
                    v-if="loadAmount > 5000"
                    class="alert alert-danger m-3"
                    role="alert"
                  >
                    <strong>Oida!</strong> Du kan maksimalt overføre kr 5000,-
                  </div>
                  <div
                    v-if="paymenterror"
                    class="alert alert-danger m-3"
                    role="alert"
                  >
                    <strong>Oida!</strong> {{ paymenterror }}
                  </div>
                  <div
                    v-if="paymentMessage"
                    class="alert alert-success m-3"
                    role="alert"
                  >
                    <strong>Vellykket!</strong> {{ paymentMessage }}
                  </div>
                  <div class="col-12" v-if="!showIframe && !payWindow">
                    <h6 v-if="Cards.length == 0" class="text-center mb-4 mt-2">
                      Ingen kort lagret
                    </h6>

                    <base-button
                      @click="chooseCard(card)"
                      v-for="card in Cards"
                      :key="card.cardId"
                      class="btn btn-outline-default mt-1"
                      type="white"
                      style="width: 100%"
                      :icon="
                        card.cardIssuer == 'Visa'
                          ? 'fa fa-cc-visa'
                          : 'fa fa-cc-mastercard'
                      "
                    >
                      {{ card.maskedPan }}
                      {{
                        card.expiryDate[2] +
                        card.expiryDate[3] +
                        "/" +
                        card.expiryDate[0] +
                        card.expiryDate[1]
                      }}
                      <span
                        @click.stop="deleteCard(card)"
                        style="
                          padding: 5px;
                          float: right;
                          vertical-align: bottom;
                          color: red;
                        "
                        class="fa fa-trash"
                      ></span>
                    </base-button>
                    <base-button
                      @click="addCard"
                      class="btn btn-outline-default mt-3"
                      type="white"
                      style="width: 100%"
                      icon="fa fa-plus-circle"
                    >
                      Legg til nytt kort
                    </base-button>
                    <base-button
                      v-if="currentHall.BingoVipps > 0"
                      @click="chooseCard(null)"
                      class="btn mt-3 p-2"
                      type="vipps"
                      style="width: 100%"
                    >
                      <img src="/img/assets/vipps.png" class="m-0 p-0" />
                    </base-button>
                  </div>
                  <div
                    class="col-12 m-0 p-0 row"
                    v-if="showIframe && !payWindow"
                  >
                    <iframe
                      :src="iframesrc"
                      frameborder="0"
                      width="100%"
                      height="450px"
                      style="overflow-x: hidden !important; overflow-y: scroll"
                    ></iframe>
                  </div>
                  <div class="col-12" v-if="payWindow">
                    <h6 v-if="choosencard" class="text-center mt-3">
                      Belaster kort
                    </h6>
                    <h6 v-if="!choosencard" class="text-center mt-3">
                      Overføre med Vipps
                    </h6>
                    <h5 v-if="choosencard" class="text-center mb-4 mt-2">
                      <i
                        :class="
                          choosencard.cardIssuer == 'Visa'
                            ? 'fa fa-cc-visa'
                            : 'fa fa-cc-mastercard'
                        "
                      ></i>
                      {{ choosencard.maskedPan }}
                      {{
                        choosencard.expiryDate[2] +
                        choosencard.expiryDate[3] +
                        "/" +
                        choosencard.expiryDate[0] +
                        choosencard.expiryDate[1]
                      }}
                    </h5>
                    <div class="row">
                      <div
                        v-for="fillAmount in fillAmounts"
                        :key="fillAmount"
                        class="col-6"
                      >
                        <button
                          type="button"
                          @click="setFillAmount(fillAmount)"
                          class="btn btn-outline-default btn-block p-2 mt-2"
                        >
                          {{ fillAmount }}
                        </button>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-2"></div>
                      <div
                        v-bind:class="{
                          'col-4': choosencard,
                          'col-8': !choosencard,
                        }"
                      >
                        <div class="form-group">
                          Ladebeløp i kr
                          <div class="input-group mb-4">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Kr</span>
                            </div>

                            <input
                              class="form-control text-dark"
                              v-model="loadAmount"
                              placeholder="Ladebeløp"
                              type="text"
                              style="text-align: right"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-if="choosencard" class="col-4">
                        <div class="form-group">
                          CVV2/CVC2
                          <div class="input-group mb-4">
                            <input
                              class="form-control text-dark"
                              ref="cvc"
                              placeholder="CVV2/CVC2 kode"
                              v-model="cardCVC"
                              type="text"
                              style="text-align: right"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text"
                                ><i class="fa fa-money-bill"></i
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2"></div>
                    </div>
                    <div class="text-center">
                      <button
                        class="btn btn-icon btn-3 btn-success mb-3"
                        :disabled="loadAmount > 5000"
                        @click="paymentSaved"
                        type="button"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fa fa-plus"></i
                        ></span>

                        <span class="btn-inner--text">Hent penger</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showCardBox = false"
                  >
                    Lukk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal bankid -->
    <div v-show="showBankIDauth">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">BankID Autentisering</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showBankIDauth = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body m-2 p-0">
                  <div
                    v-if="bankIDerror"
                    class="alert alert-danger"
                    role="alert"
                  >
                    <strong>Oida!</strong> {{ bankIDerror }}
                  </div>
                  <div
                    v-if="bankIDMessage"
                    class="alert alert-success"
                    role="alert"
                  >
                    <strong>Vellykket!</strong> {{ bankIDMessage }}
                  </div>

                  <div
                    id="auth"
                    v-show="!bankIDerror && !bankIDMessage"
                    style="height: fit-content; height: 300px"
                  ></div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showBankIDauth = false"
                  >
                    Lukk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Game modal -->
    <div v-show="showGame">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-xl" role="document">
              <div class="modal-content modal-xl-h">
                <div class="modal-header pb-1">
                  <h5 class="modal-title text-truncate">
                    Spill på {{ playHallName }}
                  </h5>

                  <div style="margin-right: -15px; display: flex">
                    <button
                      class="btn btn-primary btn-sm float-right text-white d-none d-sm-none d-md-inline-block d-xl-inline-block d-lg-inline-block"
                      @click="openGameFull"
                    >
                      Åpne fullskjerm
                    </button>
                    <button
                      class="btn btn-primary btn-sm float-right text-white d-md-none d-xl-none d-lg-none d-inline-block d-sm-inline-block"
                      @click="openGameFull"
                    >
                      <i class="fa fa-window-maximize"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm float-right text-white ml-2 d-md-none d-xl-none d-lg-none d-inline-block d-sm-inline-block"
                      @click="showGame = false"
                    >
                      &times;
                    </button>
                    <button
                      class="btn btn-danger btn-sm float-right text-white ml-2 d-none d-sm-none d-md-inline-block d-xl-inline-block d-lg-inline-block"
                      @click="showGame = false"
                    >
                      LUKK
                    </button>
                  </div>
                </div>
                <div class="modal-body m-2 p-0 modal-xl-h-90">
                  <iframe
                    :src="gameSrc"
                    frameborder="0"
                    width="100%"
                    height="100%"
                    style="overflow: hidden"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal Spillepause -->
    <div v-show="showPlayPause">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Ønsker du spillepause?</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showPlayPause = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <div
                    v-if="pausesuccess"
                    class="alert alert-success"
                    role="alert"
                  >
                    <strong>Vellykket!</strong> {{ pausesuccess }}
                  </div>
                  <p>
                    I henhold til bingoforskriften, skal en pause ha en varighet
                    på minimum 12 måneder.
                  </p>
                  <p>Etter at pausen er satt, kan den ikke oppheves manuelt.</p>
                  <p>
                    Bekreft med ditt passord, at du vil aktivere 12 måneders
                    spillepause.
                  </p>
                  <div
                    v-if="pauseerror"
                    class="alert alert-danger"
                    role="alert"
                  >
                    <strong>Oida!</strong> {{ pauseerror }}
                  </div>
                  <base-input
                    alternative
                    type="password"
                    placeholder="Passord"
                    addon-left-icon="ni ni-lock-circle-open"
                    v-model="password"
                  >
                  </base-input>
                </div>
                <div class="modal-footer">
                  <base-button type="danger" @click="submitPause"
                    >Aktiver pause</base-button
                  >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showPlayPause = false"
                  >
                    Lukk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- Modal Uttak -->
    <div v-show="showWithdrawBox">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Uttak fra spillekort</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showWithdrawBox = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div
                  class="modal-body"
                  style="overflow-y: auto; max-height: calc(100vh - 225px)"
                >
                  <div
                    v-if="uttaksuccess"
                    class="alert alert-success"
                    role="alert"
                  >
                    <strong>Utført!</strong> {{ uttaksuccess }}
                  </div>
                  <div v-if="!uttaksuccess">
                    <p>Be om uttak fra spillekort til bankkonto.</p>
                    <p>Minstebeløp som overføres er kr 500.</p>
                    <p>
                      Uttaket vil bli manuelt behandlet og vil bli trekt når den
                      behandles. Hvis du ber om et større uttak enn hva som er
                      disponibelt på kortet ved behandling, vil hele saldo
                      overføres til bankkonto, forutsatt at saldo er over
                      minstebeløp.
                    </p>
                    <p>1-4 virkedagers behandlingstid.</p>
                    <div
                      v-if="uttakerror"
                      class="alert alert-danger"
                      role="alert"
                    >
                      <strong>Oida!</strong> {{ uttakerror }}
                    </div>
                    Uttaksbeløp (Saldo:
                    {{ uttakhall ? uttakhall.balance_str : 0 }})
                    <base-input
                      alternative
                      type="number"
                      placeholder="Uttaksbeløp"
                      addon-left-icon="ni ni-money-coins"
                      v-model="uttaksbelop"
                    >
                    </base-input>
                    Overføres til kontonummer
                    <base-input
                      alternative
                      type="text"
                      placeholder="Kontonummer"
                      addon-left-icon="ni credit-card"
                      v-model="kontonummer"
                      @keypress="isNumber($event)"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="modal-footer">
                  <base-button
                    type="danger"
                    v-if="!uttaksuccess"
                    @click="submitPayOut"
                    >Send</base-button
                  >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showWithdrawBox = false"
                  >
                    Lukk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- Modal Ny ticket -->
    <div v-show="showWithdrawBox">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Uttak fra spillekort</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showWithdrawBox = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div
                  class="modal-body"
                  style="overflow-y: auto; max-height: calc(100vh - 225px)"
                >
                  <div
                    v-if="uttaksuccess"
                    class="alert alert-success"
                    role="alert"
                  >
                    <strong>Utført!</strong> {{ uttaksuccess }}
                  </div>
                  <div v-if="!uttaksuccess">
                    <p>Be om uttak fra spillekort til bankkonto.</p>
                    <p>Minstebeløp som overføres er kr 500.</p>
                    <p>
                      Uttaket vil bli manuelt behandlet og vil bli trekt når den
                      behandles. Hvis du ber om et større uttak enn hva som er
                      disponibelt på kortet ved behandling, vil hele saldo
                      overføres til bankkonto, forutsatt at saldo er over
                      minstebeløp.
                    </p>
                    <p>1-4 virkedagers behandlingstid.</p>
                    <div
                      v-if="uttakerror"
                      class="alert alert-danger"
                      role="alert"
                    >
                      <strong>Oida!</strong> {{ uttakerror }}
                    </div>
                    Uttaksbeløp (Saldo:
                    {{ uttakhall ? uttakhall.balance_str : 0 }})
                    <base-input
                      alternative
                      type="number"
                      placeholder="Uttaksbeløp"
                      addon-left-icon="ni ni-money-coins"
                      v-model="uttaksbelop"
                    >
                    </base-input>
                    Overføres til kontonummer
                    <base-input
                      alternative
                      type="text"
                      placeholder="Kontonummer"
                      addon-left-icon="ni credit-card"
                      v-model="kontonummer"
                      @keypress="isNumber($event)"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="modal-footer">
                  <base-button
                    type="danger"
                    v-if="!uttaksuccess"
                    @click="submitPayOut"
                    >Send</base-button
                  >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showWithdrawBox = false"
                  >
                    Lukk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- Modal RemoteGames -->
    <div v-show="showRemoteGamesBox">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Spill på {{ remoteGameName }}</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showRemoteGamesBox = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <div
                    class="alert alert-warning"
                    role="alert"
                    v-if="true == false"
                  >
                    <strong>Obs!</strong> Det er noen problemer med mobilenheter
                    hvis mobil går i hvilemodus eller går ut av spillet på annen
                    måte. Man må da logge inn på nytt. Eventuelle gevinster i
                    pågående spill vil utbetales til spillsaldo.
                  </div>

                  <div
                    v-if="remotesuccess"
                    class="alert alert-success"
                    role="alert"
                  >
                    <strong>Utført!</strong> {{ remotesuccess }}
                  </div>
                  <div
                    v-if="remoteerror"
                    class="alert alert-danger"
                    role="alert"
                  >
                    <strong>Oida!</strong> {{ remoteerror }}
                  </div>
                  <div v-if="!remoteDenyPlay">
                    <div v-if="hasSession">
                      <p>Du har en åpen spilløkt.</p>
                      <base-button
                        v-if="remoteGameOpen"
                        type="success"
                        class="w-100"
                        @click="continueRemoteGame"
                        >Fortsett spilløkt</base-button
                      >
                      <base-button
                        v-if="!remoteGameOpen"
                        type="secondary"
                        class="w-100"
                        disabled
                        >Spillet er stengt (Åpent 10:00 - 22:00)</base-button
                      >
                      <base-button
                        type="danger"
                        v-if="!endingSession"
                        class="w-100 mt-3"
                        @click="endRemoteGame"
                        >Avslutt spilløkt</base-button
                      >
                      <base-button
                        type="danger"
                        v-if="endingSession"
                        class="w-100 mt-3"
                        ><i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i
                      ></base-button>

                      <p class="mt-2 mb-1">
                        Ved avslutnig av spilleøkt overføres saldo tilbake til
                        spillekonto.
                      </p>

                      <p class="mt-2">
                        Hvis du vil legge til saldo, må du avslutte økt og
                        starte en ny.
                      </p>
                    </div>
                    <div v-if="!hasSession && remoteGameOpen">
                      <p>
                        Velg beløpet du vil overføre til spillet. (Maks. Kr
                        500,-)
                      </p>
                      Tilgjengelig saldo:
                      {{ remotehall ? remotehall.balance_str : null }}
                      <div class="row mb-4">
                        <div
                          v-for="fillAmount in fillAmounts"
                          :key="fillAmount"
                          class="col-3"
                        >
                          <button
                            v-if="
                              remotehall ? remotehall.balance : 0 >= fillAmount
                            "
                            type="button"
                            @click="setRemoteFillAmount(fillAmount)"
                            class="btn btn-outline-default btn-block p-2 mt-2"
                          >
                            {{ fillAmount }}
                          </button>
                        </div>
                      </div>
                      <base-input
                        alternative
                        type="number"
                        placeholder="Beløp som skal overføres"
                        addon-left-icon="ni ni-money-coins"
                        v-model="uttaksbelop"
                      >
                      </base-input>

                      <base-button
                        type="success"
                        v-if="!remoteSubmitStarted"
                        class="w-100"
                        @click="submitRemoteTransfer"
                        >Overfør beløpet til spill</base-button
                      >
                      <base-button
                        type="success"
                        v-if="remoteSubmitStarted"
                        class="w-100"
                        ><i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i
                      ></base-button>
                    </div>
                    <base-button
                      v-if="!remoteGameOpen && !hasSession"
                      type="secondary"
                      class="w-100"
                      disabled
                      >Spillet er stengt (Åpent 10:00 - 22:00)</base-button
                    >
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="showRemoteGamesBox = false"
                    >
                      Lukk
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal Alert -->
    <div v-show="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ errorAlertTitle }}</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <div class="alert alert-danger" role="alert">
                    <strong>Oida!</strong> {{ errorAlertText }}
                  </div>

                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="showAlert = false"
                    >
                      Lukk
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <modal
      :show.sync="messageModal"
      gradient="default"
      modal-classes="modal-danger modal-dialog-centered"
    >
      <h6 slot="header" class="modal-title" id="modal-title-notification">
        {{ messageObject.title }}
      </h6>

      <div class="py-3 text-center">
        <p style="white-space: pre-line">{{ messageObject.body }}</p>
      </div>

      <template slot="footer">
        <base-button
          type="white"
          @click="messageObject.func(messageObject.hall, true)"
          >Fortsett</base-button
        >
      </template>
    </modal>

    <modal
      :show.sync="transactionsData.transactionShow"
      modal-classes="modal-dialog-centered"
    >
      <h6 slot="header" class="modal-title" id="modal-title-default">
        Siste bevegelser på {{ transactionsData.transactionHall.BingoName }}
      </h6>
      <div v-if="transactionsData.customText.length > 3" v-html="transactionsData.customText" class="mb-3"></div>
        <p>Gjeldende saldo: {{ transactionsData.transactionHall.balance_str }}</p>
      <div v-if="transactionsData.transactionList.length > 0" class="py-3">
        <div
          v-for="transaksjon in transactionsData.transactionList"
          v-bind:key="transaksjon.tID"
          class="pb-2"
        >
          <div class="row">
            <small class="col-12 text-muted">{{
              moment(String(transaksjon.tDate)).format("DD.MM.YYYY HH:mm")
            }}</small>
            <div class="col-8">{{ transaksjon.tText }}</div>
            <div
              class="col-4 text-right"
              :class="transaksjon.tValue > 0 ? 'text-success' : 'text-default'"
            >
              {{ transaksjon.tValue }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="transactionsData.isLoading" class="py-3 text-center">
        <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
      </div>

      <div
        v-else-if="transactionsData.transactionList.length == 0"
        class="py-3"
      >
        Ingen bevegelser.
      </div>

      <template slot="footer">
        <base-button
          v-if="transactionsData.transactionMoreBtn"
          type="secondary"
          class="ml-auto"
          @click="
            loadMoreTransactions(transactionsData.transactionHall.BingoID)
          "
          >Last flere</base-button
        >
        <base-button
          type="white"
          @click="transactionsData.transactionShow = false"
          >Lukk</base-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
import axios from "../axios-auth";
import pureaxios from "axios";
import { isMobile } from "mobile-device-detect";
import Modal from "@/components/Modal";
import moment from "moment";
import BingoPage from "../components/BingoPage.vue";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      moment: moment,
      showCardBox: false,
      showWithdrawBox: false,
      showGame: false,
      showBankIDauth: false,
      haller: [],
      isHallsLoaded: false,
      nearhaller: [],
      error: null,
      messageModal: false,
      messageObject: { title: null, body: null, func: false, hall: null },
      postnr: "",
      Cards: null,
      currentHall: null,
      showIframe: false,
      iframesrc: "about:blank",
      transactionToken: null,
      choosencard: null,
      payWindow: false,
      bankIDerror: "",
      bankIDMessage: "",
      loadAmount: "",
      netsAmount: 0,
      cardCVC: "",
      paymentMessage: "",
      paymenterror: "",
      pauseerror: "",
      pausesuccess: "",
      showPlayPause: false,
      fillAmounts: [50, 100, 250, 500],
      gameSrc: "",
      playHallName: "",
      password: "",
      uttaksbelop: "",
      kontonummer: "",
      uttakhall: null,
      uttakerror: "",
      uttaksuccess: "",
      haspayOut: true,
      newPostnr: "",
      newAddr: "",
      newUserStarted: false,
      newCardCreationStarted: false,
      createsuccess: "",
      errorAlertTitle: "",
      errorAlertText: "",
      showAlert: false,
      createerror: "",
      showRemoteGamesBox: false,
      remoteGameOpen: false,
      remotesuccess: "",
      remoteerror: "",
      remoteDenyPlay: false,
      remotehall: null,
      hasSession: false,
      remoteGameName: "",
      remoteSubmitStarted: false,
      endingSession: false,
      continuingremotegame: false,
      remoteGameType: 0,
      hs_window: null,
      transactionsData: {
        transactionList: [],
        transactionHall: 0,
        customText: "",
        transactionShow: false,
        transactionMoreBtn: true,
        isLoading: true,
      },
    };
  },
  created() {
    Object.defineProperty(this, "hs_window", {
      value: null,
      writable: true,
      configurable: true,
      enumerable: false,
    });
  },
  mounted() {
    // check is param id is set
    console.log("mounted, v2");
    this.updateHalls();
    this.$store.dispatch("startMessagePuller");
    //Sjekk kort etter 2 sekunder for å se om noen nye kommer inn.
    setTimeout(() => {
      this.updateHalls();
    }, 2000);

    window.addEventListener("message", (message) => {
      if (message.data == "finished") {
        this.paymentMessage = "Lagring av kort vellykket";

        this.getStoredCards();
        this.showIframe = false;
      }
      if (message.data == "failed") {
        this.paymenterror = "Lagring av kort feilet";
        this.getStoredCards();
        this.showIframe = false;
      }

      if (message.data == "finishedPayment") {
        this.paymentMessage = "Betalingen var vellykket";
        this.showIframe = false;
        setTimeout(() => {
          this.getHallBalance(this.currentHall);
        }, 3000);
      }
      if (message.data == "failedPayment") {
        this.paymenterror = "Betalingen feilet";
        this.showIframe = false;
      }
    });
    },
  computed: {
    messages() {
      return this.$store.getters.getMessages;
    },
  },
  methods: {
    updateHalls() {
      axios
        .post(
          "/halls/my",
          {},
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {


          if (
            this.haller == null ||
            this.checkHallDiff(this.haller, res.data.halls)
          ) {
            this.haller = res.data.halls;

            let listHaspayOut = false;

            this.haller.forEach((hall) => {
              this.getHallBalance(hall);
              if (hall.BingoPayOut == 1) {
                listHaspayOut = true;
              }
            });
            this.hallsLoaded();
            if (!listHaspayOut) this.haspayOut = false;
          }
        });
    },
    setFillAmount(amount) {
      this.loadAmount = amount;
      if (this.choosencard) {
        this.$refs.cvc.focus();
      }
    },
    hallsLoaded() {
      if(this.isHallsLoaded) {
        return;
      }
      this.isHallsLoaded = true;

      if (this.$route.params.function === 'tr') {
        let currentBingo = this.haller.find(hall => hall.BingoID === parseInt(this.$route.params.id));

        this.openTransactionsBox(currentBingo);

        // Remove the 'function' parameter from the URL
        const { id } = this.$route.params;
        this.$router.replace({
          name: this.$route.name,
          params: { id } // keep id for bingohall id
        });
      }
    },
    setRemoteFillAmount(amount) {
      this.uttaksbelop = amount;
    },
    geoLocation() {
      console.log("Geolocation");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        this.error = "Geolocation is not supported by this browser.";
      }
    },
    checkHallDiff(array1, array2) {
      if (
        array1.reduce((a, b) => a + b.BingoID, 0) !=
        array2.reduce((a, b) => a + b.BingoID, 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    showPosition(position) {
      axios
        .post("/halls", {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        })
        .then((res) => {
          this.haller = res.data.halls;
        });
    },
    addCard(clientId) {
      this.paymentMessage = "";
      this.paymenterror = "";

      axios
        .post(
          "/payment/registerCard",
          { bingoid: this.currentHall.BingoID },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          this.transactionToken = res.data.transactionId;
          this.showIframe = true;
          this.iframesrc =
            "https://epayment.nets.eu/Terminal/default.aspx?merchantId=" +
            this.currentHall.nets_clientid +
            "&transactionId=" +
            this.transactionToken;
        });
    },
    playPause() {
      this.showPlayPause = true;
    },
    submitPause() {
      axios
        .post(
          "/user/playPause",
          {
            password: this.password,
          },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          this.pausesuccess = "Spillepause er nå aktivert";
          this.pauseerror = "";
          this.updateHalls();
        })
        .catch((error) => {
          this.pauseerror = "Passordet ser ut til å være feil";
        });
    },
    createUser() {
      if (isNaN(this.newPostnr) || this.newPostnr.length != 4) {
        this.createerror = "Postnummer må være 4 tall";
        return;
      }
      if (this.newAddr.length < 3) {
        this.createerror = "Du må skrive en adresse";
        return;
      }

      if (this.newUserStarted) return;

      this.createerror = "";
      this.createsuccess = "";

      this.newUserStarted = true;

      axios
        .post("/halls/findNearest", {
          postnr: this.newPostnr,
          filter: this.$store.state.ownerFilter,
        })
        .then((res) => {
          this.nearhaller = res.data.halls;
          this.createsuccess = "";
          this.createerror = "";
        })
        .catch((error) => {
          this.newUserStarted = false;
          this.createerror =
            "Det oppsto en feil. Sjekk om inntastet informasjon er riktig.";
        });
    },
    submitPayOut() {
      this.uttaksuccess = "";
      this.uttakerror = "";

      if (isNaN(this.uttaksbelop)) {
        this.uttaksbelop = parseInt(this.uttaksbelop.trim().replace(/\D/g, ""));
      }

      let _kontonummer = this.kontonummer.toString().trim();

      if (isNaN(_kontonummer)) {
        _kontonummer = parseInt(_kontonummer.toString().replace(/\D/g, ""));
      }

      if (this.uttaksbelop > this.uttakhall.balance) {
        this.uttakerror = "Du kan ikke be om uttak større en saldo.";
        return;
      }

      if (this.uttaksbelop < 500) {
        this.uttakerror = "Minste uttaksbeløp kr 500,-";
        return;
      }
      if (_kontonummer.toString().length < 11) {
        this.uttakerror = "Kontonummer må bestå av 11 siffer";
        return;
      }

      if (!this.validateKontonummerMod11(_kontonummer.toString())) {
        this.uttakerror =
          "Angitt kontonummer er ikke gyldig norsk bankkontonummer.";
        return;
      }

      axios
        .post(
          "/payment/withDraw",
          {
            bingoid: this.uttakhall.BingoID,
            amount: this.uttaksbelop,
            acc: _kontonummer,
          },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          this.uttaksuccess = "Sendt til behandling";
          this.uttakerror = "";
          this.uttaksbelop = 0;
        })
        .catch((error) => {
          this.uttakerror = "Det oppsto en feil";
        });
    },
    /*continueRemoteGame() {
      if (this.continuingremotegame) return;

      this.continuingremotegame = true;
      let ortiz_window = window.open("", "ortiz");

      axios
        .post(
          "/extgame/continueGameSession",
          {
            bingoid: this.remotehall.BingoID,
            gameType: this.remoteGameType,
          },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          this.showRemoteGamesBox = false;

          let device = "D";
          if (isMobile) device = "M";

          ortiz_window.location.replace(
            "https://ortiz.dinbingo.no/api/rgs-api/1.1/launch?opid=" +
              res.data.opid +
              "&gid=501&ul=no&t=" +
              res.data.token +
              "&uc=NOK&cid=" +
              res.data.cid +
              "&ly=" +
              device +
              "&p=P"
          );
          //ortiz_window.location.replace("https://ortiz.bingosystem.no/api/rgs-api/1.1/launch?opid="+ res.data.opid +"&gid="+ this.remoteGameType +"&ul=no&t="+ res.data.token +"&uc=NOK&cid=" + res.data.cid + "&ly=" + device + "&p=P", "ortiz");
        })
        .catch((error) => {
          this.endingSession = false;
          this.remoteerror = "Det oppsto en feil ved stenging.";
          ortiz_window.close();
          this.remoteSubmitStarted = false;
        });
    },
    endRemoteGame() {
      if (this.endingSession) return;

      this.endingSession = true;

      axios
        .post(
          "/extgame/endGameSession",
          {
            bingoid: this.remotehall.BingoID,
            gameType: this.remoteGameType,
          },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          setTimeout(() => {
            this.getHallBalance(this.remotehall);
            this.hasSession = false;
            this.endingSession = false;
          }, 2000);
        })
        .catch((error) => {
          this.endingSession = false;
          this.remoteerror = "Det oppsto en feil ved stenging.";
          this.remoteSubmitStarted = false;
        });
    },
    submitRemoteTransfer() {
      if (this.remoteSubmitStarted) return;

      this.remoteSubmitStarted = true;

      if (isNaN(this.uttaksbelop)) {
        this.uttaksbelop = parseInt(this.uttaksbelop.trim().replace(/\D/g, ""));
      }

      if (this.uttaksbelop > 500) {
        this.remoteerror = "Du kan maks overføre kr 500,-";
        this.remoteSubmitStarted = false;
        return;
      }

      if (this.uttaksbelop > this.remotehall.balance) {
        this.remoteerror = "Du kan ikke overføre større beløp enn saldo.";
        this.remoteSubmitStarted = false;
        return;
      }

      let ortiz_window = window.open("", "ortiz");

      axios
        .post(
          "/extgame/startGameSession",
          {
            bingoid: this.remotehall.BingoID,
            gameType: this.remoteGameType,
            amount: this.uttaksbelop,
          },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          this.remotesuccess = "Overføring bekreftet";
          this.remoteerror = "";
          this.uttaksbelop = 0;
          this.remoteSubmitStarted = false;
          this.showRemoteGamesBox = false;

          let device = "D";
          if (isMobile) device = "M";

          ortiz_window.location.replace(
            "https://ortiz.dinbingo.no/api/rgs-api/1.1/launch?opid=" +
              res.data.opid +
              "&gid=501&ul=no&t=" +
              res.data.token +
              "&uc=NOK&cid=" +
              res.data.cid +
              "&ly=" +
              device +
              "&p=P"
          );
          //ortiz_window.location.replace("https://ortiz.bingosystem.no/api/rgs-api/1.1/launch?opid="+ res.data.opid +"&gid="+ this.remoteGameType +"&ul=no&t="+ res.data.token +"&uc=NOK&cid=" + res.data.cid + "&ly=" + device + "&p=P", "ortiz");
          //console.log("https://ortiz.bingosystem.no/api/rgs-api/1.1/launch?opid="+ res.data.opid +"&gid="+ this.remoteGameType +"&ul=no&t="+ res.data.token +"&uc=NOK&cid=" + res.data.cid + "&ly=" + device + "&p=P");

          //window.open(
          //      "https://ortiz.bingosystem.no/api/rgs-api/1.1/launch?opid="+ res.data.opid +"&gid=501&ul=no&t="+ res.data.token +"&uc=NOK&cid=" + res.data.cid + "&ly="+device+"&p=P", "ortiz");

          this.getHallBalance(this.remotehall);
        })
        .catch((error) => {
          ortiz_window.close();
          this.remoteerror = "Det oppsto en feil";

          this.remoteSubmitStarted = false;

          if (error.response.data.error && error.response.data.error == 99) {
            this.remoteerror =
              "Kunne ikke trekke penger fra konto. Er saldo for lav?";
          }
        });
    },*/
    chooseCard(card) {
      this.paymentMessage = "";
      this.paymenterror = "";

      this.choosencard = card;

      this.payWindow = true;
    },
    deleteCard(card) {
      this.paymentMessage = "";
      this.paymenterror = "";

      if (confirm("Vil du slette bankkort?")) {
        axios
          .post(
            "/payment/deleteCard",
            { cardId: card.cardId },
            {
              headers: { Authorization: "Bearer " + this.$store.state.idToken },
            }
          )
          .then((res) => {
            this.paymentMessage = "Kort slettet";
            this.getStoredCards(this.currentHall);
          });
      }
      //this.payWindow = true;
    },
    postnrKeyUp() {
      if (this.postnr.length > 3) {
        axios.post("/halls", { postnr: this.postnr }).then((res) => {
          this.haller = res.data.halls;
          //console.log(res.data.halls)
        });
      }
    },
    openGameFull() {
      window.location.href = this.gameSrc;
    },
    async chooseBingo(hall) {
      if (this.newCardCreationStarted) return;

      this.newCardCreationStarted = true;

      axios
        .post(
          "/user/createCard",
          {
            postnr: this.newPostnr,
            addr: this.newAddr,
            bingoid: hall.BingoID,
            filter: this.$store.state.ownerFilter,
          },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          this.createsuccess = "Spillekonto blir generert.";
          this.createerror = "";
          this.newAddr = "";
          this.newPostnr = "";

          setTimeout(() => {
            this.createsuccess = "Spillekonto generert!";
            this.updateHalls();
          }, 3000);
        })
        .catch((error) => {
          this.newUserStarted = false;
          this.newCardCreationStarted = false;

          this.createerror =
            "Det oppsto en feil ved generering av spillekonto.";
        });
    },
    async openCardBox(hall) {
      this.paymentMessage = "";
      this.paymenterror = "";

      //alert(hall.BingoID + " " +hall.BingoName);
      await this.getStoredCards(hall);

      this.showCardBox = true;
    },
    async withdrawCardBox(hall) {
      this.uttaksuccess = "";
      this.uttakerror = "";

      this.uttakhall = hall;

      this.uttaksbelop = "";
      this.kontonummer = "";

      //alert(hall.BingoID + " " +hall.BingoName);
      // await this.getStoredCards(hall);

      this.showWithdrawBox = true;
    },
    async playMainGame(hall, overrideMsg = false) {
      try {
        if (this.hs_window != null) {
          this.hs_window.close();
        }
      } catch (e) {
        this.hs_window = null;
        console.log(e);
      }
      this.hs_window = window.open("", "HOVEDSPILL");

      this.messageModal = false;
      if (!overrideMsg) {
        let gotMessage = await this.getMessages(1, hall.BingoID);

        if (gotMessage) {
          this.messageObject.hall = hall;

          this.messageObject.title = gotMessage.title;
          this.messageObject.body = gotMessage.text.replaceAll(/\\n/g, "\n");
          this.messageObject.func = this.playMainGame;
          this.messageModal = true;
          this.hs_window.close();

          return;
        }
      }

      console.log("continue");

      axios
        .post(
          "/game/getGameToken",
          { GameType: 1, bingoid: hall.BingoID },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          //console.log(res.data)
          if (res.data.otherGames && res.data.otherGames > 0) {
            this.hs_window.close();
            this.errorAlertTitle = "Kan ikke åpne spill";
            this.errorAlertText =
              "Du har allerede et aktivt hovedspill2. Kjøpte bonger må spilles ferdig før du kan åpne dette spillet.";

            this.showAlert = true;
            return;
          }

          if (res.data.activeGames && res.data.activeGames > 0) {
            this.hs_window.close();
            this.errorAlertTitle = "Kan ikke åpne spill";
            this.errorAlertText =
              "Du spiller allerede hovedspill i en annen hall hos samme operatør.";

            this.showAlert = true;
            return;
          }

          pureaxios.defaults.withCredentials = true;
          pureaxios
            .post("https://maingame.dinbingo.no/game", {
              token: res.data.gameToken,
            })
            .then((res) => {
              // console.log(res.data)
              // this.playHallName = hall.BingoName
              // this.gameSrc = 'https://maingame.dinbingo.no/game/' + hall.BingoID
              //
              // this.showGame = true;

              this.hs_window.location.replace(
                "https://maingame.dinbingo.no/game/" + hall.BingoID
              );
              this.hs_window.focus();
            });
        });
    },
    async playMainGame2(hall, overrideMsg = false) {
      try {
        if (this.hs_window != null) {
          this.hs_window.close();
        }
      } catch (e) {
        this.hs_window = null;
        console.log(e);
      }
      this.hs_window = window.open("", "HOVEDSPILL");

      this.messageModal = false;
      if (!overrideMsg) {
        let gotMessage = await this.getMessages(1, hall.BingoID);

        if (gotMessage) {
          this.messageObject.hall = hall;

          this.messageObject.title = gotMessage.title;
          this.messageObject.body = gotMessage.text.replaceAll(/\\n/g, "\n");
          this.messageObject.func = this.playMainGame2;
          this.messageModal = true;
          this.hs_window.close();
          return;
        }
      }

      axios
        .post(
          "/game/getGameToken",
          { GameType: 2, bingoid: hall.BingoID },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          //console.log(res.data)
          if (res.data.otherGames && res.data.otherGames > 0) {
            this.hs_window.close();

            this.errorAlertTitle = "Kan ikke åpne spill";
            this.errorAlertText =
              "Du har allerede et annet aktivt hovedspill2. Kjøpte bonger må spilles ferdig før du kan åpne dette spillet.";

            this.showAlert = true;
            return;
          }

          if (res.data.activeGames && res.data.activeGames > 0) {
            this.hs_window.close();

            this.errorAlertTitle = "Kan ikke åpne spill";
            this.errorAlertText =
              "Du spiller allerede et annet hovedspill hos samme operatør. Kjøpte bonger må spilles ferdig før du kan åpne dette spillet.";

            this.showAlert = true;
            return;
          }

          pureaxios.defaults.withCredentials = true;
          pureaxios
            .post("https://maingame2.dinbingo.no/game", {
              token: res.data.gameToken,
            })
            .then((res) => {
              //console.log(res.data)
              //this.playHallName = hall.BingoName
              //this.gameSrc = 'https://maingame.bingosystem.no/game/' + hall.BingoID

              //this.showGame = true;
              this.hs_window.location.replace(
                "https://maingame2.dinbingo.no/game/" + hall.BingoID
              );
            });
        });
    },
    async playMainGame3(hall, overrideMsg = false) {
      try {
        if (this.hs_window != null) {
          this.hs_window.close();
        }
      } catch (e) {
        this.hs_window = null;
        console.log(e);
      }
      this.hs_window = window.open("", "HOVEDSPILL");

      this.messageModal = false;
      if (!overrideMsg) {
        let gotMessage = await this.getMessages(3, hall.BingoID);

        if (gotMessage) {
          this.messageObject.hall = hall;

          this.messageObject.title = gotMessage.title;
          this.messageObject.body = gotMessage.text.replaceAll(/\\n/g, "\n");
          this.messageObject.func = this.playMainGame3;
          this.messageModal = true;
          this.hs_window.close();
          return;
        }
      }

      axios
        .post(
          "/game/getGameToken",
          { GameType: 3, bingoid: hall.BingoID },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          //console.log(res.data)
          if (res.data.otherGames && res.data.otherGames > 0) {
            this.hs_window.close();

            this.errorAlertTitle = "Kan ikke åpne spill";
            this.errorAlertText =
              "Du har allerede et annet aktivt hovedspill2. Kjøpte bonger må spilles ferdig før du kan åpne dette spillet.";

            this.showAlert = true;
            return;
          }

          if (res.data.activeGames && res.data.activeGames > 0) {
            this.hs_window.close();

            this.errorAlertTitle = "Kan ikke åpne spill";
            this.errorAlertText =
              "Du spiller allerede et annet hovedspill hos samme operatør. Kjøpte bonger må spilles ferdig før du kan åpne dette spillet.";

            this.showAlert = true;
            return;
          }

          pureaxios.defaults.withCredentials = true;
          pureaxios
            .post("https://maingame3.dinbingo.no/game", {
              token: res.data.gameToken,
            })
            .then((res) => {
              //console.log(res.data)
              //this.playHallName = hall.BingoName
              //this.gameSrc = 'https://maingame.bingosystem.no/game/' + hall.BingoID

              //this.showGame = true;
              this.hs_window.location.replace(
                "https://maingame3.dinbingo.no/game/" + hall.BingoID
              );
            });
        });
    },
    async getMessages(gameid, bingoid) {
      return await axios
        .post(
          "/user/getMessages",
          { GameType: gameid, bingoid: bingoid },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          return res.data;
        });
    },
    async getTransactions(bingoid, fromTransactionID) {
      return await axios
        .post(
          "/user/getTransactions",
          { bingoid: bingoid, TransactionID: fromTransactionID },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          if (res.data.length < 25)
            this.transactionsData.transactionMoreBtn = false;
          return res.data;
        });
    },
    async getTransactionsCustomText(bingoid) {
      return await axios
          .get(
              "/wap/getTransactionText?bingoId=" + bingoid,
              {
                headers: { Authorization: "Bearer " + this.$store.state.idToken },
              }
          )
          .then((res) => {
            console.log(res.data.transactionText);
            return res.data.transactionText;
          });
    },
    async openTransactionsBox(bingo) {
      this.transactionsData.transactionMoreBtn = true;
      this.transactionsData.transactionList = [];
      this.transactionsData.customText = "";
      this.transactionsData.transactionShow = true;
      this.transactionsData.transactionHall = bingo;
      this.getTransactionsCustomText(bingo.BingoID)
          .then((customText) => {
            this.transactionsData.customText = (customText || '').toString().replace(/\n/g, '<br>');
          })
          .catch((error) => {
            console.error('Error fetching custom text:', error);
          });
      this.transactionsData.transactionList = await this.getTransactions(
        bingo.BingoID,
        0
      );

      this.transactionsData.isLoading = false;
    },
    async loadMoreTransactions(bingoid) {
      this.transactionsData.isLoading = true;
      this.transactionsData.transactionList =
        this.transactionsData.transactionList.concat(
          await this.getTransactions(
            bingoid,
            this.transactionsData.transactionList[
              this.transactionsData.transactionList.length - 1
            ].tID
          )
        );
      this.transactionsData.isLoading = false;
    },
    async playRemoteMainGame(hall, gametype, gameName) {
      this.remoteGameOpen = false;

      axios
        .post(
          "/extgame/checkGameSession",
          { bingoid: hall.BingoID, gameType: gametype },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {

          this.hasSession = false;
          this.remoteDenyPlay = false;
          this.remoteerror = "";
          this.remotesuccess = "";
          this.remoteGameType = gametype;
          this.uttaksbelop = "";

          if (res.data.authToken && res.data.authToken != "") {
            this.hasSession = true;
          }

          if (
            res.data.activeGames > 0 ||
            (res.data.otherGames > 0 && this.hasSession == false)
          ) {
            this.remoteDenyPlay = true;
            this.remoteerror =
              "Du har et annet aktivt bingospill hos samme operatør. Ny type spill kan ikke startes før alle kjøpte bonger i andre spill er ferdigspilt, og spill lukket.";
            console.log("Deny remote game");
          }

          this.remoteGameOpen = res.data.gameOpen;
          this.remoteGameName = gameName;
          this.remotehall = hall;

          this.showRemoteGamesBox = true;
        });

      /*axios
        .post('/game/getGameToken', { GameType: 1, bingoid: hall.BingoID }, {
            headers: { Authorization: "Bearer " + this.$store.state.idToken }
        })
        .then(res => {
          console.log(res.data)
          pureaxios.defaults.withCredentials = true
          pureaxios
          .post('https://maingame.dinbingo.no/game', { token: res.data.gameToken }
          )
          .then(res => {

            console.log(res.data)
            this.playHallName = hall.BingoName
            this.gameSrc = 'https://maingame.dinbingo.no/game/' + hall.BingoID

            this.showGame = true;
          
        })


        })*/
    },
    getStoredCards(hall) {
      axios
        .post(
          "/payment/myCards",
          { nets_clientid: hall.nets_clientid },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          console.log(res.data.cardInfo);
          this.showIframe = false;
          this.payWindow = false;
          this.netsAmount = 0;
          this.Cards = res.data.cardInfo;
          this.currentHall = hall;
        });
    },
    paymentSaved() {
      if (this.choosencard) {
        axios
          .post(
            "/payment/storedPayment",
            {
              cardId: this.choosencard.cardId,
              bingoid: this.currentHall.BingoID,
              cardCVC: this.cardCVC,
              loadAmount: this.loadAmount,
            },
            {
              headers: { Authorization: "Bearer " + this.$store.state.idToken },
            }
          )
          .then((res) => {
            this.cardCVC = "";
            this.netsAmount = this.loadAmount;
            this.loadAmount = "";
            this.transactionToken = res.data.transactionId;
            console.log(res.data.transactionId);
            this.showIframe = true;
            this.payWindow = false;
            this.iframesrc =
              "https://epayment.nets.eu/Terminal/default.aspx?merchantId=" +
              this.currentHall.nets_clientid +
              "&transactionId=" +
              this.transactionToken;
          });
      } else {
        axios
          .post(
            "/payment/vippsPayment",
            { bingoid: this.currentHall.BingoID, loadAmount: this.loadAmount },
            {
              headers: { Authorization: "Bearer " + this.$store.state.idToken },
            }
          )
          .then((res) => {
            this.cardCVC = "";
            this.netsAmount = this.loadAmount;
            this.loadAmount = "";
            this.transactionToken = res.data.transactionId;
            console.log(res.data.transactionId);
            this.showIframe = true;
            this.payWindow = false;
            this.iframesrc =
              "https://epayment.nets.eu/Terminal/default.aspx?merchantId=" +
              this.currentHall.nets_clientid +
              "&transactionId=" +
              this.transactionToken;
          });
      }
    },
    startBankIDauth() {
      this.bankIDerror = "";
      this.bankIDMessage = "";

      this.showCardBox = false;
      this.showBankIDauth = true;
      const div = document.querySelector("#auth");
      [].slice.call(div.children).forEach((element) => {
        div.removeChild(element);
      });

      OIDC.doInit({
        oidc_url:
          "https://auth.bankid.no/auth/realms/prod/.well-known/openid-configuration",
        client_id: "dinbingono_707428b1-bankid-prod",
        redirect_uri: "https://dinbingo.no/register",
        method: "redirect",
        nonce: this.$store.state.idToken,
      })
        .then(() => {
          console.log("Kobler til bankid");
          OIDC.doConnect({
            config: {
              login_hint: "",
              scope: "openid profile nnin_altsub",
            },
            callback: this.bankIdResult,
          });
        })
        .catch(console.log);
    },
    bankIdResult: function (err, data) {
      if (err) {
        console.log(err);
        this.bankIDCanceled = true;
        this.showIframe = false;
      } else {
        return axios
          .post("/bankid/authBankID", {
            code: data.code,
          })
          .then((res) => {
            console.log(res);
            this.regUser = res.data;
            this.showIframe = false;
            if (this.regUser.result == "success") {
              this.bankIDerror = "";
              this.bankIDMessage = "Din bruker er nå verifisert med BankID";
              this.$store.commit('noBankID', false);
              localStorage.setItem("noBankID", false);
            } else {
              this.bankIDMessage = "";

              if (this.regUser.reason == "userExist") {
                this.bankIDerror =
                  "Denne BankID er allerede knyttet til en annen bruker.";
              } else if (this.regUser.reason == "age") {
                this.bankIDerror =
                  "Pengespill i Norge er kun tillatt til personer over 18 år.";
              } else {
                this.bankIDerror = "Det oppsto en feil.";
              }
            }
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (err) {
        return;
      }
      console.log(data);
    },
    getHallBalance(hall) {
      axios
        .post(
          "/banking/balance",
          { bingoid: hall.BingoID },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          if (isNaN(res.data.balance)) {
            hall.balance = 0;
            hall.balance_str = "Kortfeil - kontakt betjening";
          } else {
            hall.balance = res.data.balance;
            hall.balance_str =
              "kr " + new Intl.NumberFormat().format(res.data.balance);
          }

          console.log(res.data);
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateKontonummerMod11(kontonummer) {
      const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
      const kontonummerWithoutSpacesAndPeriods = kontonummer.replace(
        /[\s.]+/g,
        ""
      );
      if (kontonummerWithoutSpacesAndPeriods.length !== 11) {
        return false;
      } else {
        const sjekksiffer = Number.parseInt(
          kontonummerWithoutSpacesAndPeriods.charAt(10)
        );
        const kontonummerUtenSjekksiffer =
          kontonummerWithoutSpacesAndPeriods.substring(0, 10);

        let sum = 0;
        for (let index = 0; index < 10; index++) {
          sum +=
            Number.parseInt(kontonummerUtenSjekksiffer.charAt(index)) *
            weights[index];
        }

        let computedSjekksiffer = 11 - (sum % 11);
        if (computedSjekksiffer == 11) {
          computedSjekksiffer = 0;
        }

        return computedSjekksiffer === sjekksiffer;
      }
    },
  },
};
</script>
<style scoped>
.center-button {
  display: block;
  margin: 0 auto;
}
.modal-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-dialog {
  z-index: 9999;
  max-width: 550px !important;
}
.modal-wrapper {
  z-index: 9998;
  display: table-cell;
  vertical-align: middle;
}
.modal-xl {
  z-index: 9999;
  max-width: 1200px !important;
  height: 98% !important;
}
.modal-xl-h {
  height: 100% !important;
}
.modal-xl-h-90 {
  display: block !important;
  height: 90% !important;
}
</style>
