import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Components from "./views/Components.vue";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Forgot from "./views/Forgot.vue";
import Mypage from "./views/Mypage.vue";
import Template from "./views/Template.vue";
import Bingohalls from "./views/Bingohalls.vue";
import HowToPlay from "./views/HowToPlay.vue";
import H2News from "./views/NewsH2.vue";
import Article from "./views/Article.vue";
import NetsCallback from "./views/Netscallback.vue";
import QrPage from "./views/QrPage.vue";
import AMLFormWizard from "@/components/AMLFormWizard.vue";
import UserDataPage from "@/views/UserDataPage.vue";
Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
    },
    {
      path: "/components",
      name: "components",
      components: {
        header: AppHeader,
        default: Components,
        footer: AppFooter,
      },
    },
    {
      path: "/template",
      name: "template",
      components: {
        header: AppHeader,
        default: Template,
        footer: AppFooter,
      },
    },
    {
      path: "/howtoplay",
      name: "howtoplay",
      components: {
        header: AppHeader,
        default: HowToPlay,
        footer: AppFooter,
      },
    },
    {
      path: "/newsh2",
      name: "newsh2",
      components: {
        header: AppHeader,
        default: H2News,
        footer: AppFooter,
      },
    },

    {
      path: "/bingohalls",
      name: "bingohalls",
      components: {
        header: AppHeader,
        default: Bingohalls,
        footer: AppFooter,
      },
    },
    {
      path: "/login",
      name: "login",
      components: {
        header: AppHeader,
        default: Login,
        footer: AppFooter,
      },
    },
    {
      path: "/register",
      name: "register",
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter,
      },
    },
    {
      path: "/forgot",
      name: "forgot",
      components: {
        header: AppHeader,
        default: Forgot,
        footer: AppFooter,
      },
    },
    {
      path: "/article/:id",
      name: "article",
      components: {
        header: AppHeader,
        default: Article,
        footer: AppFooter,
      },
    },
    {
      path: "/mypage",
      name: "mypage",
      components: {
        header: AppHeader,
        default: Mypage,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        if (store.state.idToken) {
          next();
        } else {
          store.dispatch("tryAutoLogin");
          if (store.state.idToken) {
            next();
          } else {
            next("/login");
          }
        }
      },
    },
    {
      path: "/mybingo/:id/:function?",
      name: "mybingo",
      components: {
        header: AppHeader,
        default: Mypage,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        if (store.state.idToken) {
          next();
        } else {
          store.dispatch("tryAutoLogin");
          if (store.state.idToken) {
            next();
          } else {
            next({
              path: "/login",
              query: { redirect: to.fullPath },
            });
          }
        }
      },
    },
    {
      path: "/user",
      name: "userdata",
      components: {
        header: AppHeader,
        default: Mypage,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        if (store.state.idToken) {
          next();
        } else {
          store.dispatch("tryAutoLogin");
          if (store.state.idToken) {
            next();
          } else {
            next({
              path: "/login",
              query: { redirect: to.fullPath },
            });
          }
        }
      },
    },
    {
      path: "/user/details",
      name: "confirmUserData",
      components: {
        header: AppHeader,
        default: UserDataPage,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        if (store.state.idToken) {
          next();
        } else {
          store.dispatch("tryAutoLogin");
          if (store.state.idToken) {
            next();
          } else {
            next({
              path: "/login",
              query: { redirect: to.fullPath },
            });
          }
        }
      },
    },
    {
      path: "/qr/:data",
      name: "qr",
      components: {
        header: AppHeader,
        default: QrPage,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        console.log("beforeEnter");
        if (store.state.idToken) {
          next();
        } else {
          store.dispatch("tryAutoLogin");
          if (store.state.idToken) {
            next();
          } else {
            next({
              path: "/login",
              query: { redirect: to.fullPath },
            });
          }
        }
      },
    },

    {
      path: "/ok",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        store.state.ownerFilter = 1;
        next();
      },
    },
    {
      path: "/linkbingo",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        store.state.ownerFilter = 10;
        next();
      },
    },
    {
      path: "/grenlandsbingo",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        store.state.ownerFilter = 4;
        next();
      },
    },
    {
      path: "/maxi",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        store.state.ownerFilter = 51;
        next();
      },
    },
    {
      path: "/bingo",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        store.state.ownerFilter = 50;
        next();
      },
    },
    {
      path: "/lykkespill",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
      beforeEnter(to, from, next) {
        store.state.ownerFilter = 15;
        next();
      },
    },
    {
      path: "/netsCallback",
      name: "netscallback",
      components: {
        default: NetsCallback,
      } /*,
      beforeEnter (to, from, next) {
        if(store.state.idToken) {
          next()
        }else{
          next('/login')
        }
      }*/,
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
